<template>
  <div class="common_sub_container">
    <h2>REPORTS</h2>
    <section>
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose a Report Type
                  <span></span>
                </label>
                <select v-model="post_data.report_type">
                  <option value="monthly">Monthly Summary</option>
                  <option value="ptaeo">PTAEO</option>
                  <option value="ptaeo_details">PTAEO Details</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Division
                  <span></span>
                </label>
                <select v-model="post_data.requestdivision">
                  <option value="all">All</option>
                  <option
                    v-for="(division,i) in divisions"
                    :key="'divisions'+i"
                    :value="division.name"
                  >{{division.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Unit
                  <span></span>
                </label>
                <select v-model="post_data.requestunit">
                  <option value="all">All</option>
                  <option
                    v-for="(unit,i) in units"
                    :key="'units'+i"
                    :value="unit.name"
                  >{{unit.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby">
                  <option value="requestdivision">Division</option>
                  <option value="requestunit">Unit</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchCourier">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section>
      <div class="despatch_summary_wrapper">
        <header>
          <h3>Registry Dispatch System Monthly Summary</h3>
          <div class="right_part">
            <a title="Download">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container">
          <table>
            <tr>
              <td>Group By</td>
              <td>November</td>
              <td>December</td>
              <td>January</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "Reports",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: moment(new Date()).format("YYYY-MM-DD"),
      date: null,
      enableTime: true,
      divisions: [],
      units: [],
      couriers: [],
      post_data: {
        from_date: moment(new Date()).format("YYYY-MM-DD"),
        to_date: moment(new Date()).format("YYYY-MM-DD"),
        groupby: "requestunit",
        requestdivision: "",
        requestunit: "",
        report_type: ""
      },
      configDate: {
        mode: "range"
      }
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Reports"]);
    this.fetchDivision();
    this.fetchUnit();
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from_date = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to_date = moment(daterange[1]).format("YYYY-MM-DD");
    },
    fetchDivision() {
      this.axios
        .get("/api/division/")
        .then(response => {
          this.divisions = response.data.divisions;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchUnit() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.units = response.data.branches;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchCourier() {
      console.log(this.post_data);
      this.axios
        .post("/api/reports/", this.post_data)
        .then(response => {
          console.log(response);
          this.couriers = response.data.data;
        })
        .catch(error => {
          this.toast.error();
          console.log(e);
        });
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 20%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>